/* Use custom elements in all popular browsers */
import "@ungap/custom-elements";

import "htmx.org";

import Button from "govuk-frontend/govuk-esm/components/button/button.mjs";

const $buttons = document.querySelectorAll('[data-module="govuk-button"]');
$buttons.forEach(($button) => {
    new Button($button).init();
});

import Details from "govuk-frontend/govuk-esm/components/details/details.mjs";

const $details = document.querySelectorAll('[data-module="govuk-details"]');
$details.forEach(($detail) => {
    new Details($detail).init();
});

import ErrorSummary from "govuk-frontend/govuk-esm/components/error-summary/error-summary.mjs";

const $errorSummary = document.querySelector(
    '[data-module="govuk-error-summary"]',
);
new ErrorSummary($errorSummary).init();

/* GPAS Components */
import ConfirmButton from "./components/confirm-button.js";

window.customElements.define("confirm-button", ConfirmButton, {
    extends: "dialog",
});

import DetailsMenu from "./components/details-menu.js";

window.customElements.define("details-menu", DetailsMenu, {
    extends: "details",
});

import SelectMenu from "./components/select-menu.js";

window.customElements.define("select-menu", SelectMenu, {
    extends: "details",
});

import DataTable from "./components/data-table.js";

window.customElements.define("data-table", DataTable, {
    extends: "form",
});
